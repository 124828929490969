<template>
  <form @submit="login" :validation-schema="formSchema" class="py-2">
    <FormField v-slot="{ componentField }" name="email">
      <FormItem v-auto-animate>
        <!-- <FormLabel>Email</FormLabel> -->
        <FormControl>
          <Input ref="username" v-bind="componentField" :disabled="loading" autocomplete="email" type="text"
            placeholder="Email" />
        </FormControl>
        <FormMessage class="font-light ml-3" />
      </FormItem>
    </FormField>

    <FormField v-slot="{ componentField }" name="password">
      <FormItem v-auto-animate class="py-2">
        <!-- <FormLabel>Password</FormLabel> -->
        <FormControl>
          <Input v-bind="componentField" :disabled="loading" autocomplete="current-password"
            :type="showPassword ? 'text' : 'password'" placeholder="Password" />
          <button type="button" @click.stop="() => { showPassword = !showPassword }"
            class="absolute end-0 top-0 min-h-9 items-center" variant="outline" size="icon">
            <span class=" inset-y-0 px-2">
              <icon :name="showPassword ? 'lucide:eye' : 'lucide:eye-off'"
                class="w-6 h-4 text-muted-foreground" />
            </span>
          </button>
        </FormControl>
        <FormMessage class="font-light ml-3" />
      </FormItem>
    </FormField>

    <div v-if="showMfaInput" class="py-2">
      <FormField v-slot="{ componentField }" name="mfaCode">
        <FormItem v-auto-animate>
          <FormControl>
            <Input v-model="mfaCode" :disabled="loading" type="text" placeholder="Enter MFA code" />
          </FormControl>
          <FormMessage class="font-light ml-3" />
        </FormItem>
      </FormField>
      <Button type="button" @click="verifyMfa" :disabled="!mfaCode" class="mt-2">
        <Loader2 v-if="loading" class="w-4 h-4 mr-2 animate-spin" />
        Verify MFA Code
      </Button>
    </div>

    <div v-else class="grid grid-cols-1 gap-4 py-3">
      <nuxt-link to="/forgot-password" class="no-underline pb-4">
        <span type="" class="font-medium text-muted-foreground hover:underline underline-offset-4 text-xs">
          Forgot your password?
        </span>
      </nuxt-link>

      <Button type="submit" class="text-[1em] squircle-10" :disabled="errors.email || errors.password || loading">
        <Loader2 v-if="loading && usingEmailPassword" class="w-4 h-4 mr-2 animate-spin" />
        Login
      </Button>
      
      <SocialLogin />

      
    </div>
  </form>
</template>

<script setup lang="ts">
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import * as z from 'zod'
import { Button } from '@/components/ui/button'
import { Loader2 } from 'lucide-vue-next'
import { FormField, FormItem, FormControl, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useFocus } from '@vueuse/core'
import SocialLogin from './SocialLogin.vue'
import { vAutoAnimate } from '@formkit/auto-animate'

const username = ref()
const { focused } = useFocus(username, { initialValue: true })

const client = useSupabaseClient()
const loading = ref(false)
const usingEmailPassword = ref(false)
const showPassword = ref(false)

const showMfaInput = ref(false)
const factorId = ref<string | null>(null)
const mfaCode = ref('')

const formSchema = toTypedSchema(z.object({
  email: z.string().email(),
  password: z.string().min(8),
}))

const apiErrors = ref({
  wrongCredentials: false,
  accountNotVerified: false,
  accountSuspended: false,
  mfaRequired: false
})

const apiErrorMessages = computed(() => {
  return {
    email: apiErrors.value.wrongCredentials ? 'Incorrect username or password' :
      apiErrors.value.accountSuspended ? 'Account suspended' : '',
    password: apiErrors.value.accountNotVerified ? 'Account not verified' : ''
  };
});

const { validate, errors, resetForm, handleSubmit, setErrors } = useForm({
  validationSchema: formSchema
})

const route = useRoute()

const login = handleSubmit(async (values) => {
  usingEmailPassword.value = true
  loading.value = true
  const validator = await validate();
  if (validator.valid) {
    const { data, error } = await client.auth.signInWithPassword({
      email: values.email,
      password: values.password
    })

    if (error) {
      if (error.status === 400 && error.message === 'MFA required') {
        // MFA is required
        factorId.value = error.data.factorId
        showMfaInput.value = true
        loading.value = false
      } else {
        // Other errors
        loading.value = false
        apiErrors.value.wrongCredentials = error.message === "Invalid login credentials"
        // Handle other errors as needed
        if (apiErrors.value.wrongCredentials || apiErrors.value.accountNotVerified || apiErrors.value.accountSuspended) {
          setErrors({
            email: apiErrorMessages.value.email,
            password: apiErrorMessages.value.password
          })
        }
      }
    } else {
      // Successful login
      loading.value = false
      await navigateTo('/dashboard')
    }
  } else {
    loading.value = false
  }
})

const verifyMfa = async () => {
  loading.value = true
  const { data, error } = await client.auth.verifyOtp({
    factorId: factorId.value!,
    type: 'totp',
    token: mfaCode.value
  })

  if (error) {
    loading.value = false
    // Handle MFA verification error
    setErrors({ mfaCode: error.message })
  } else {
    // MFA verified successfully
    loading.value = false
    await navigateTo('/dashboard')
  }
}
</script>